import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import styles from "./o-nas.module.css";
import Container from "../components/container";

export const query = graphql`
  query {
    questyLogo: file(relativePath: { eq: "questy-logo-new.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    synergiusLogo: file(relativePath: { eq: "synergius-logo1.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    empireoLogo: file(
      relativePath: { eq: "Empireo-system-do-zarzadzania-firma.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    auraLogo: file(relativePath: { eq: "Aura-Business.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ONas = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Questy - producent CRM, poznaj nas"
        description="Producent CRM - Questy • Poznaj systemy CRM oferowane przez firmę Questy - producenta kompleksowych rozwiązań dla biznesu • QuickCRM "
      />
      <Hero maxHeight="280px" justifyContent="center">
        <h1>Questy</h1>
        <p>Producent kompleksowych rozwiązań dla biznesu</p>
      </Hero>
      <Container>
        <div className={styles.about}>

          <div className={styles.aboutText}>
           <h2 style={{
            textAlign: "center",
            paddingTop: 10,
          }}>Kim jesteśmy?</h2>
          <p> Jesteśmy firmą <strong><a href="https://questy.pl">Questy </a></strong>– doświadczonym producentem i dostawcą 
            oprogramowania dla biznesu, w tym systemu Quick CRM. Od 2002 roku działamy na polskim rynku B2B, 
            wspierając klientów biznesowych rozwiązaniami informatycznymi, które<strong> pomagają realizować założone 
            strategie i osiągać ambitne cele</strong>. Naszymi klientami są&nbsp;zarówno duże, rozpoznawalne firmy, jak i 
            mniejsze, ale dynamicznie rozwijające się przedsiębiorstwa.</p>
            <p>Naszą misją jest<strong> dostarczanie rozwiązań informatycznych, które wspierają rozwój 
            biznesu</strong>. Chcemy przekazywać naszym klientom dopasowane i elastyczne narzędzia, które 
            sprawią, że będą robić to, co robią, z&nbsp;jeszcze lepszymi efektami. W&nbsp;dłuższym okresie<strong> dążymy do 
            zostania najlepszym dostawcą rozwiązań informatycznych </strong>wykorzystywanych w&nbsp;organizacjach 
            na całym świecie.</p>
          </div>
          <Img
            className={styles.aboutImage}
            style={{
              textAlign: "center",
              maxWidth: "300px",
              width: "100%",
              height: "100%",
              marginTop: "120px"
            }}
            fluid={data.questyLogo.childImageSharp.fluid}
            alt="Questy – producent oprogramowania"
          />
        </div>
      </Container>
      <section>
        <div
          style={{
            padding: 0
          }}
          className="container container--text"
        >
          <h2 style={{
              textAlign: "center",
              paddingTop: 5,
            paddingBottom: 40,
          }}>Sprawdź inne nasze rozwiązania CRM:</h2>
          <div className="text-answers">
            <div className="text-block">
              <div
                style={{
                  flex: 1
                }}
              >
              <br / >
                <a href="https://synergiuscrm.pl"><strong><Img
                  style={{
                    maxWidth: "300px",
                    margin: "0 auto"
                  }}
                  fluid={data.synergiusLogo.childImageSharp.fluid}
                  alt="Synergius CRM system do zarządzania sprzedażą"
                /></strong></a>
                
              </div>
              <h4 style={{
            paddingTop: 30,
            paddingBottom: 10,
          }}>System CRM zintegrowany z programem handlowo-magazynowym</h4>
              <p>
                <a href="https://synergiuscrm.pl"><strong>Synergius CRM </strong></a>
                to łatwe w obsłudze rozwiązanie, które wspiera
                zarządzanie sprzedażą w firmie. Oprócz stałego dostępu do
                wszystkich istotnych informacji, łatwiejsze staje się także
                planowanie i raportowanie pracy. Gdy&nbsp;wybrana wersja systemu
                przestanie być wystarczająca, istnieje możliwość rozbudowania
                systemu o kolejne funkcje lub przejścia na inne rozwiązanie bez
                kosztów migracji danych. Synergius CRM może także zostać
                zintegrowany z programem handlowo-magazynowym.
              </p>
            </div>
            <div className="text-block">
              <div
                style={{
                  flex: 1
                }}
              >
                <a href="https://empireo.pl"><strong><Img
                  style={{
                    maxWidth: "300px",
                    margin: "0 auto"
                  }}
                  fluid={data.empireoLogo.childImageSharp.fluid}
                  alt="Empireo dla agencji reklamowych"
                /></strong></a>
              </div>
              <h4 style={{
                paddingTop: 10,
            paddingBottom: 10,
          }}>
                System CRM z fakturami, magazynem i zarządzaniem procesami
              </h4>
              <p>
                <a href="https://empireo.pl"><strong>Empireo</strong></a> to niezwykle elastyczny system do zarządzania firmą.
                Możesz go w&nbsp;maksymalnym stopniu dopasować do potrzeb Twojego
                biznesu. Wspiera on&nbsp;kluczowe obszary funkcjonowania firmy. Jest
                to możliwe dzięki bogatemu zakresowi funkcjonalnemu, do którego
                należą: CRM, faktury i płatności, zarządzanie procesami,
                ofertowanie i magazyn.
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingTop: 0
          }}
          className="container container--text"
        >
          <div
            style={{
              flex: 1
            }}
          >
            <a href="https://aurabusiness.pl/"><strong><Img
              style={{
                maxWidth: "300px",
                margin: "0 auto"
              }}
              fluid={data.auraLogo.childImageSharp.fluid}
              alt="Aura Business rozwiązania dedykowane"
            /></strong></a>
          </div>
          <h4 style={{
            textAlign: "center",
                paddingTop: 25,
            paddingBottom: 10,
          }}>
            Dedykowany system CRM komplementarny dla systemów ERP
          </h4>
          <p>
            <a href="https://aurabusiness.pl/"><strong>Aura Business</strong></a> to dedykowane i komplementarne dla ERP narzędzia
            wsparcia sprzedaży, marketingu i zarządzania usługami. System
            przeznaczony jest dla&nbsp;organizacji oczekujących kompleksowych
            rozwiązań i usprawnienia procesów biznesowych. Uwzględnimy Twoje
            indywidualne wymagania, dopasowując technologię do wizji rozwoju
            Twojego przedsiębiorstwa.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default ONas;
